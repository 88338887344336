<template>
  <div class="tab-container">
    <overlay-loader :loading="isLoading" />
    <hcc-input
      type="text"
      size="lg"
      icon=""
      placeholder="Search..."
      class="tab__search-input"
      name="Search"
      v-model="searchData"
    >
      <template #icon><magnify-icon /></template>
    </hcc-input>
    <div class="files-tab-container">
      <hcc-card
        class="file-card"
        bodyClass="file__body"
        v-for="file in documentsLists"
        :key="file.id"
      >
        <div class="file">
          <file-pdf-icon class="file__icon" />
          <span
            v-if="file.document.filename.length >= 25 || windowWidth <= 1440"
          >
            {{ truncateWithDots(file.document.filename, 8) }}
          </span>
          <span v-else>
            {{ file.document.filename }}
          </span>
          <div class="file__icon-group">
            <hcc-button-icon
              @click="downloadMedia(file, token)"
              class="file__icon"
              v-tooltip="$t('campaigns.data-bank.download')"
            >
              <download-icon />
            </hcc-button-icon>
            <hcc-button-icon
              @click="deleteFile(file.id)"
              class="file__icon"
              v-tooltip="$t('campaigns.data-bank.delete')"
            >
              <delete-icon />
            </hcc-button-icon>
          </div>
        </div>
      </hcc-card>
      <div class="file__drop">
        <drop-zone
          :fileType="fileType.join(',')"
          :order="order"
          :type="$t('campaigns.data-bank.files.type')"
          :title="$t('campaigns.data-bank.files.add-file')"
          :theme="'list'"
        />
      </div>
      <hcc-confirmation />
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus';
import { UPDATE_DATA_BANK } from '@/eventTypes';
import { getToken } from '@/vue-apollo';
import downloadMedia from '@/utils/downloadMedia';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';

export default {
  props: {
    databank: {
      required: true,
    },
  },
  components: {
    OverlayLoader,
    HccInput: () => import('@/components/shared/HccInput/index.vue'),
    HccCard: () => import('@/components/shared/HccCard/index.vue'),
    HccButtonIcon: () => import('@/components/shared/HccButtonIcon/index.vue'),
    DropZone: () => import('@/components/settings/DropZone.vue'),
    HccConfirmation: () => import('@/components/shared/HccConfirmation/index.vue'),
  },
  watch: {
    'databank.document': function setDoc(newDoc) {
      this.documentList = newDoc || [];
    },
  },
  data() {
    return {
      documentList: [],
      searchData: '',
      token: getToken(),
      fileType: ['.pdf', '.doc', '.docx', '.ods'],
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.documentList = this.databank.document || [];
    window.addEventListener('resize', this.updateWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWidth);
  },
  computed: {
    documentsLists() {
      return this.documentList.filter(f => f.document.url != null)
        .filter((file) => {
          const name = file.document.filename.toLowerCase();
          const value = this.searchData.toLowerCase();
          return name.includes(value);
        });
    },
    order() {
      return this.documentList.length;
    },
    isLoading() {
      return this.databank.document === null;
    },
  },
  methods: {
    deleteFile(props) {
      this.$modal.show('confirmation', {
        title: this.$t('common.delete'),
        description: this.$t('configuration.confirmation'),
        variant: 'error',
        confirm: () => {
          EventBus.$emit(UPDATE_DATA_BANK, {
            data: { id: props },
            remove: true,
            type: this.$t('campaigns.data-bank.files.type'),
          });
        },
      });
    },
    downloadMedia,
    updateWidth() {
      this.windowWidth = window.innerWidth;
    },
  },

};
</script>

<style scoped lang="scss">
@import "~styles/components/settings/dataBank/_files_tab_data_bank.scss";
</style>
